import { trim } from 'lodash';
import { DateTime } from 'luxon';

import type {
  ActionInput,
  DetailedCategorizationActionLineInput,
  Maybe,
  Metadata,
  RuleComparisonInput,
  TransferRuleInput,
} from '@/api-svc-types';

export type TransferRuleData = Omit<TransferRuleInput, 'valueRules' | 'action' | 'afterDateSEC' | 'beforeDateSEC'>;

type Dates = {
  afterDate: string;
  beforeDate: string;
  timezone: string;
};

function strTypeGuard(str: Maybe<string> | undefined | null): asserts str is string {
  if (!str) {
    throw Error('Cannot be empty string, or undefined, or null');
  }
}

const mapActionDataToActionInput = (action: ActionInput): ActionInput => {
  const {
    type,
    categoryId,
    contactId,
    feeContactId,
    feeCategoryId,
    feePercentageSplits,
    percentageSplits,
    deFiWalletId,
    ignoreFailPricing,
  } = action;

  if (type === 'Ignore') {
    return {
      type,
    };
  }

  if (type === 'SimpleCategorization') {
    strTypeGuard(categoryId);
    strTypeGuard(contactId);
    return {
      type,
      categoryId,
      contactId,
      feeCategoryId,
      feeContactId,
      ignoreFailPricing,
    };
  }

  if (type === 'TradeCategorization') {
    strTypeGuard(feeContactId);
    return {
      type,
      feeContactId,
      ignoreFailPricing,
    };
  }

  if (type === 'InternalTransferCategorization') {
    strTypeGuard(feeContactId);
    return {
      type,
      feeContactId,
      ignoreFailPricing,
    };
  }

  if (type === 'SimpleSplitCategorization') {
    feePercentageSplits?.map((split) => {
      split.percentage = parseInt(split.percentage.toString());
    });
    percentageSplits?.map((split) => {
      split.percentage = parseInt(split.percentage.toString());
    });
    return {
      type,
      feePercentageSplits,
      percentageSplits,
    };
  }
  if (type === 'DetailedCategorization') {
    const lines = action.lines ?? [];
    return {
      type,
      ignoreFailPricing,
      lines: lines.length
        ? lines.map((line: DetailedCategorizationActionLineInput) => {
            const { lineQualifierExtractor, metadataIds, ...otherAttrs } = line;
            const { __typename, ...others } = otherAttrs as any;
            return {
              ...others,
              // eslint-disable-next-line no-unneeded-ternary
              lineQualifierExtractor: lineQualifierExtractor ? lineQualifierExtractor : null,
              metadataIds: metadataIds && metadataIds.length ? metadataIds.filter((id: string | null) => id) : null,
            };
          })
        : null,
    };
  }

  if (type === 'DeFiCategorization') {
    strTypeGuard(feeContactId);
    strTypeGuard(deFiWalletId);
    return {
      type,
      feeContactId,
      deFiWalletId,
    };
  }

  throw Error('Invalid parameter, please check Contact Id, Category Id, and Categorization.');
};

const validateValueRules = (valueRule: RuleComparisonInput) => {
  const { comparison, value } = valueRule;
  strTypeGuard(comparison);
  strTypeGuard(value);
};

export const mapTransferRulDataToTransferRuleInput = (
  rule: TransferRuleData,
  valueRules: RuleComparisonInput[],
  action: ActionInput,
  dates: Dates
): TransferRuleInput => {
  const { timezone, afterDate, beforeDate } = dates;

  //  luxon lib toSeconds return decimal
  const afterDateSECWithMill = afterDate
    ? DateTime.fromISO(afterDate, { zone: timezone }).startOf('day').toSeconds()
    : undefined;

  const afterDateSEC = afterDateSECWithMill ? Math.round(afterDateSECWithMill) : undefined;

  const beforeDateSECWithMill = beforeDate
    ? DateTime.fromISO(beforeDate, { zone: timezone }).endOf('day').toSeconds()
    : undefined;

  const beforeDateSEC = beforeDateSECWithMill ? Math.floor(beforeDateSECWithMill) : undefined;

  const actionInput = mapActionDataToActionInput(action);
  const type = actionInput.type;

  valueRules.forEach(validateValueRules);

  const transferRule: Partial<TransferRuleInput> = {
    action: actionInput,
    valueRules: valueRules.length ? valueRules : undefined,
    afterDateSEC,
    beforeDateSEC,
  };

  for (const entry of Object.entries(rule)) {
    const [key, value] = entry as [keyof TransferRuleData, any];

    if (key === 'fromAddress' && value) {
      const trimmedFromAddress = trim(value);
      transferRule[key] = trimmedFromAddress;
      continue;
    }

    if (key === 'toAddress' && value) {
      const trimmedToAddress = trim(value);
      transferRule[key] = trimmedToAddress;
      continue;
    }

    if (type === 'DetailedCategorization') {
      if (key === 'autoReconcile' || key === 'collapseValues') {
        transferRule[key] = undefined;
        continue;
      }
    }

    if (value !== '' && value !== null && value !== undefined) {
      transferRule[key] = value as any;
    } else {
      transferRule[key] = undefined;
    }
  }

  return transferRule as TransferRuleInput;
};

export const ruleVarFactory = (rule: TransferRuleInput) => ({
  transfer: rule,
});

export function snakeToPascal(str: string): string {
  return str.replace(/^[a-z]/, (c) => c.toUpperCase()).replace(/_[a-z]/g, (g) => g[1].toUpperCase());
}

export function pascalToSnake(str: string): string {
  return str.replace(/[a-z][A-Z]/g, (g) => g[0] + '_' + g[1]).toLowerCase();
}

export type MetadataByType = {
  type: string;
  metadata: Metadata[];
};

export function groupMetadataByType(metadata: Metadata[]): MetadataByType[] {
  const map = new Map<string, Metadata[]>();

  for (const m of metadata) {
    const exist = map.get(m.remoteType);
    if (!exist) {
      map.set(m.remoteType, [m]);
    } else {
      exist.push(m);
    }
  }

  return Array.from(map.entries()).map(([type, metadata]) => {
    return {
      type,
      metadata,
    };
  });
}
