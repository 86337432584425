





















































































































































































































































































































































































































































































































































































































import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import {
  ActionInput,
  ActionType,
  AssetExtractor,
  Category,
  Comparison,
  Connection,
  ConnectionStatus,
  Contact,
  DeFiCategorizationAction,
  DetailedCategorizationAction,
  DetailedCategorizationActionLine,
  DetailedCategorizationActionLineInput,
  InternalTransferCategorizationAction,
  Metadata,
  MetadataOperator,
  MetadataPair,
  PercentageSplitCategorizationInput,
  RuleComparisonInput,
  SimpleCategorizationAction,
  SimpleSplitCategorizationAction,
  TradeCategorizationAction,
  TransferRule,
  TransferRuleInput,
  ValueExtractor,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { MUT_SNACKBAR } from '@/store';
import { isAccountingProvider } from '@/utils/accountingProviders';
import { assertDefined } from '@/utils/guards';

import UiButton from '../ui/UiButton.vue';
import UiButtonToggle from '../ui/UiButtonToggle.vue';
import UiCheckbox from '../ui/UiCheckbox.vue';
import UiDatePicker2 from '../ui/UiDatePicker2.vue';
import UiModal from '../ui/UiModal.vue';
import UiSelect2 from '../ui/UiSelect2.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';
import {
  ActionType2,
  actionTypes,
  advanceCategorization,
  AdvanceCategorizationType,
  assetExtractorTypes,
  directions,
  priorities,
  RulesTxnType,
  txnCategories,
  valueExtractorTypes,
} from './constants';
import {
  ActionKey,
  actions,
  AdvancedConditionKey,
  advancedConditions,
  ConditionKey,
  conditionMetadataFields,
  conditions,
  isConditionKey,
  isRuleKey,
  RuleKey,
} from './fields-config';
import RuleCondition from './RuleCondition.vue';
import RuleValidation2 from './RuleValidation2.vue';
import {
  groupMetadataByType,
  mapTransferRulDataToTransferRuleInput,
  MetadataByType,
  ruleVarFactory,
} from './utilities';

type Tab = 'details' | 'preview';

@Component({
  apollo: {
    metadata: {
      query: gql`
        query getMetadata($orgId: ID!, $connectionId: ID, $includeDisabled: Boolean) {
          metadata(orgId: $orgId, connectionId: $connectionId, includeDisabled: $includeDisabled) {
            id
            enabled
            source
            metaType
            name
            remoteType
            connectionId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          connectionId: this.ruleFormData.accountingConnectionId,
          includeDisabled: false,
        };
      },
      // skip() {
      //   return this.$store.state.currentOrg.id && this.ruleFormData.accountingConnectionId;
      // },
      update(data) {
        if (!data.metadata) {
          return [];
        }

        return data.metadata.filter((m: Metadata | null) => Boolean(m));
      },
    },
  },
  components: {
    UiButton,
    UiButtonToggle,
    UiCheckbox,
    UiDatePicker2,
    UiModal,
    UiSelect2,
    UiTextEdit,
    RuleCondition,
    RuleValidation2,
  },
})
export default class RuleModal2 extends BaseVue {
  @Prop({ default: false })
  public open!: boolean;

  @Prop({ required: true })
  public readonly connections!: Connection[];

  @Prop({ default: null })
  readonly rule!: TransferRule | null;

  @Prop({ default: () => undefined })
  readonly refresh!: () => unknown;

  @Prop()
  readonly predefinedConditions?: Record<ConditionKey, unknown>;

  public selectedTab: Tab = 'details';

  public conditions = { ...conditions };
  public advancedConditions = { ...advancedConditions };
  public get metadataFields() {
    return conditionMetadataFields.filter((field) => !field.featureFlag || this.checkFeatureFlag(field.featureFlag));
  }

  public readonly actions = actions;

  public ruleFormData: any = {
    id: null,
    disabled: false,
    name: null,
    priority: 1,
    description: null,
  };

  public conditionsFormData: any = {
    walletIds: null,
    assets: null,
    multiToken: false,
    methodId: null,
    direction: null,
    afterDate: null,
    beforeDate: null,
    fromAddress: null,
    toAddress: null,
    subTxnFromAddress: null,
    subTxnToAddress: null,
    fromAssetQty: null,
    toAssetQty: null,
    transactionType: 'standard',
    autoCategorizeFee: false,
    includesCurrency: null,
    selectedMetadataField: null,
    metadataInput: null,
  };

  public actionsFormData: any = {
    actionType: null,
    advanceCategorize: null,
    accountingConnectionId: null,
    collapseValues: false,
    ignoreFailPricing: false,
    categoryId: null,
    contactId: null,
    feeCategoryId: null,
    feeContactId: null,
    deFiWalletId: null,
  };

  public splitsFormData: {
    percentageSplits: PercentageSplitCategorizationInput[];
    feePercentageSplits: PercentageSplitCategorizationInput[];
  } = {
    percentageSplits: [],
    feePercentageSplits: [],
  };

  public lines: DetailedCategorizationActionLineInput[] = [];
  public collapsedLines: boolean[] = [];

  public readonly priorities = priorities.map((p) => ({ id: p.id, value: `${p.value}` }));
  public readonly directions = directions;
  public readonly txnCategories = txnCategories;
  public readonly assetExtractorTypes = assetExtractorTypes;
  public readonly valueExtractorTypes = valueExtractorTypes;
  public readonly identifierRules = [{ label: 'Contains Coin(s)' }];
  public readonly visibleActionTypes: any[] = [...actionTypes];
  public readonly advanceCategorizeOptions = advanceCategorization;

  public selectedIdentifierRule = this.identifierRules[0];
  public expandedAdvancedConditions = false;
  public errorLine: string | null = null;

  public visibleConditions: string[] = [];

  metadata: Metadata[] = [];

  get wallets() {
    return this.$store.getters['wallets/WALLETS'];
  }

  get deFiWallets() {
    return this.wallets.filter((x: any) => x.type === 22);
  }

  get categories() {
    if (!this.actionsFormData.accountingConnectionId) return [];

    return this.$store.getters['categories/ENABLE_CATEGORIES']
      .filter((c: Category) => c.accountingConnectionId === this.actionsFormData.accountingConnectionId)
      .map((c: Category) => ({ id: c.id, name: `${c.code} ${c.name}`, type: c.type }));
  }

  get contacts() {
    if (!this.actionsFormData.accountingConnectionId) {
      return [];
    }
    return this.$store.getters['contacts/ENABLED_CONTACTS']
      .filter((c: Contact) => c.accountingConnectionId === this.actionsFormData.accountingConnectionId)
      .map((c: Contact) => ({ id: c.id, name: c.name, caption: `${c.type} - ${this._getRemoteID(c)}` }));
  }

  get filteredMetadata(): MetadataByType[] {
    if (!this.actionsFormData.accountingConnectionId) {
      return [];
    }

    const m = this.metadata.filter((m) => m.connectionId === this.actionsFormData.accountingConnectionId);
    return groupMetadataByType(m);
  }

  @Watch('actionsFormData.actionType')
  public onActionChange(value: string) {
    if (value !== 'advancedCategorize') this.$set(this.actionsFormData, 'advanceCategorize', null);
  }

  @Watch('rule')
  watchRuleChange(newValue: TransferRule | null, oldValue: TransferRule | null) {
    if (newValue && newValue !== oldValue && this.open) {
      this._populateForm();
    }
  }

  @Watch('predefinedConditions')
  predefinedConditionsChange() {
    if (!this.predefinedConditions) return;

    for (const key of Object.keys(this.predefinedConditions)) {
      const conditionKey = key as ConditionKey;

      if (key === 'afterDate' || key === 'beforeDate') {
        if (!this.predefinedConditions[conditionKey]) continue;

        this.$set(this.conditionsFormData, key, this.predefinedConditions[conditionKey]);
        this._handleExpandOrCollapse(this.conditions, 'date', true);
        if (!this.visibleConditions.includes('date')) this.visibleConditions.push('date');
      } else {
        this._populateCondition(conditionKey, this.predefinedConditions[conditionKey]);
      }
    }
  }

  @Watch('visibleConditions')
  public onConditionSelect(current: any[], previous: any[]) {
    if (current.length > previous.length) {
      const addedCondition = current.find((c) => !previous.includes(c));
      this.expandOrCollapseCondition(addedCondition, true);
    } else {
      const removedCondition = previous.find((p) => !current.includes(p));
      if (removedCondition) this.expandOrCollapseCondition(removedCondition, false);
    }
  }

  private _getRemoteID(item: Contact) {
    const split = item.id.split('.');
    split.shift();
    return split.join('.');
  }

  public getTabClass(view: Tab) {
    if (view === this.selectedTab) {
      return 'tw-bg-neutral-500 tw-text-neutral-100 tw-px-4 tw-py-4 tw-font-medium tw-text-sm';
    } else {
      return 'tw-text-gray-500 hover:tw-text-gray-700 tw-px-4 tw-py-4 tw-font-medium tw-text-sm';
    }
  }

  public getIterableConditions(excludeAlwaysVisible?: boolean): { key: ConditionKey; name?: string }[] {
    const iterableConditions = (Object.keys(this.conditions) as ConditionKey[])
      .filter(
        (key) => !this.conditions[key].featureFlag || this.checkFeatureFlag(this.conditions[key].featureFlag as string)
      )
      .map((key) => ({
        key,
        label: this.conditions[key].label,
      }));

    if (excludeAlwaysVisible) return iterableConditions.filter((c) => !this.conditions[c.key].alwaysVisible);
    return iterableConditions;
  }

  public get getIterableAdvancedConditions() {
    return Object.keys(this.advancedConditions) as AdvancedConditionKey[];
  }

  public get iteratableSplitTypes() {
    return Object.keys(this.splitsFormData) as ('percentageSplits' | 'feePercentageSplits')[];
  }

  public get shouldShowManualAccountingConnection(): boolean {
    const manualContactExist = this.$store.getters['contacts/ENABLED_CONTACTS']?.some(
      (c: Contact) => c.source === 'Manual'
    );
    return (
      manualContactExist ||
      this.$store.getters['categories/ENABLE_CATEGORIES']?.some((c: Category) => c.source === 'Manual')
    );
  }

  public get validAccountingConnections() {
    const ac = this.connections.filter((c) => !c.isDisabled && isAccountingProvider(c.provider));

    const manualAccountingConnection: Connection = {
      id: 'Manual',
      provider: 'Bitwave' as any,
      status: ConnectionStatus.Ok,
    };

    if (this.shouldShowManualAccountingConnection) {
      return [manualAccountingConnection].concat(ac);
    }

    return ac;
  }

  public expandOrCollapseCondition(conditionKey: ConditionKey, expanded: boolean) {
    this._handleExpandOrCollapse(this.conditions, conditionKey, expanded);

    if (!expanded && this.visibleConditions.includes(conditionKey)) {
      this.visibleConditions = this.visibleConditions.filter((c) => c !== conditionKey);
    }
  }

  public expandOrCollapseAdvancedCondition(conditionKey: AdvancedConditionKey, expanded: boolean) {
    this._handleExpandOrCollapse(this.advancedConditions, conditionKey, expanded);
  }

  public onTxnCategoryChange(value: string) {
    this.$set(this.conditionsFormData, 'transactionType', value);

    // Might want to do a mapping of available fields per transaction type later on
    // both the actions and txn types list will expand
    const advCatIndex = this.visibleActionTypes.findIndex((actionType) => actionType.id === 'advancedCategorize');
    const disabledAdvCat = value !== 'standard';
    this.$set(this.visibleActionTypes[advCatIndex], 'disabled', disabledAdvCat);

    if (this.actionsFormData.actionType === 'advancedCategorize' && disabledAdvCat) {
      this.$set(this.actionsFormData, 'actionType', null);
    }
  }

  public isApplicableAction(actionKey: ActionKey) {
    const _action = this.actions[actionKey];
    const _selectedAction = this.actionsFormData.actionType as ActionType2 | null;
    const _selectedTxnType = this.conditionsFormData.transactionType as RulesTxnType;
    const _selectedAdvCat = this.actionsFormData.advanceCategorize as AdvanceCategorizationType | null;

    const satisifiesAction =
      !_action.actionsWhenApplicable || (_selectedAction && _action.actionsWhenApplicable.includes(_selectedAction));
    const satisifiesTxnType =
      !_action.txnTypesWhenApplicable || _action.txnTypesWhenApplicable.includes(_selectedTxnType);
    const satisfiesAdvCat =
      !_action.advCatWhenApplicable ||
      _selectedAction !== 'advancedCategorize' ||
      (_selectedAdvCat && _action.advCatWhenApplicable.includes(_selectedAdvCat));

    return satisifiesAction && satisifiesTxnType && satisfiesAdvCat;
  }

  public addSplit(type: 'percentageSplits' | 'feePercentageSplits') {
    this.splitsFormData[type].push({
      categoryId: '',
      contactId: '',
      percentage: 100,
    });
  }

  public removeSplit(type: 'percentageSplits' | 'feePercentageSplits', index: number) {
    this.splitsFormData[type].splice(index, 1);
  }

  public getSplitTotal(type: 'percentageSplits' | 'feePercentageSplits') {
    return this.splitsFormData[type].map((s) => s.percentage).reduce((acc, curr) => Number(acc) + Number(curr), 0);
  }

  public addLine() {
    this.lines.push({
      valueExtractor: '' as ValueExtractor,
      assetExtractor: '' as AssetExtractor,
      lineQualifierExtractor: null,
      categoryId: '',
      contactId: '',
      metadataIds: [],
    });
    this.collapsedLines.push(false);
  }

  public removeLine(index: number) {
    this.lines.splice(index, 1);
    this.collapsedLines.splice(index, 1);
  }

  public toggleLine(index: number) {
    this.$set(this.collapsedLines, index, !this.collapsedLines[index]);
  }

  public async saveRule() {
    this.$set(this, 'errorLine', null);

    const { afterDate, beforeDate } = this.conditionsFormData;
    const timezone = this.$store.state.currentOrg.timezone;
    const dates = { afterDate, beforeDate, timezone };
    const respType = this.rule ? 'updateRule' : 'createRule';

    if (!this._checkFormValidity()) {
      const message = 'Problem saving rule: Make sure all required fields are completed!';
      this.showErrorSnackbar(message);
      this.$set(this, 'errorLine', message);
      return;
    }

    try {
      const rule = mapTransferRulDataToTransferRuleInput(
        this._getTransferRule(),
        this._assetQtyToComparison(),
        this._getActionInput(),
        dates
      );

      const resp = this.rule ? await this._updateRule(rule) : await this._createRule(rule);

      if (resp.data[respType].success) {
        this._showSnackbar('success', (this.$t('_successRule') as string).replace('[ACTION]', 'Saved'));
        this.refresh();

        // if editing, open the validation tab otherwise close the modal
        if (this.rule) this.selectedTab = 'preview';
        else this.closeModal();
      } else {
        this.showErrorSnackbar('Problem saving rule: ' + resp.data[respType].errors.join('<br />'));
        this.$set(this, 'errorLine', 'Problem saving rule');
      }
    } catch (e) {
      this._showSnackbar(
        'error',
        (this.$t('_errorRule') as string).replace('[ACTION]', 'Saving') + ': ' + (e as { message: string })?.message
      );
    }
  }

  public closeModal() {
    this._resetData();
    this.refresh();
    this.$emit('close', false);
  }

  private _createRule(rule: TransferRuleInput) {
    const orgId = this.$store.state.currentOrg.id;
    return this.$apollo.mutate({
      mutation: gql`
        mutation CreateRule($orgId: ID!, $rule: Rule!) {
          createRule(orgId: $orgId, rule: $rule) {
            success
            errors
          }
        }
      `,
      variables: {
        orgId,
        rule: ruleVarFactory(rule),
      },
    });
  }

  private _updateRule(rule: TransferRuleInput) {
    assertDefined(this.rule?.id);
    const ruleId = this.rule.id;
    const orgId = this.$store.state.currentOrg.id;

    return this.$apollo.mutate({
      mutation: gql`
        mutation UpdateRule($orgId: ID!, $ruleId: ID!, $rule: Rule!) {
          updateRule(orgId: $orgId, ruleId: $ruleId, rule: $rule) {
            success
            errors
          }
        }
      `,
      variables: {
        orgId,
        ruleId,
        rule: ruleVarFactory(rule),
      },
    });
  }

  private _checkFormValidity() {
    if (
      !this.ruleFormData.name ||
      !this.ruleFormData.priority ||
      !this.actionsFormData.actionType ||
      !this.actionsFormData.accountingConnectionId ||
      !this.conditionsFormData.direction
    ) {
      return false;
    }

    for (const actionKey of Object.keys(this.actions)) {
      const key = actionKey as ActionKey;
      if (this.actions[key].required && this.isApplicableAction(key) && !this.actionsFormData[key]) {
        // workaround for fee contact, not required if fee categorization is not checked
        // if we need other workarounds like this might want to think of a better way to validate
        if (key !== 'feeContactId' || this.conditionsFormData.autoCategorizeFee) {
          return false;
        }
      }
    }

    if (this.isApplicableAction('percentageSplits') && this.getSplitTotal('percentageSplits') !== 100) {
      return false;
    }
    if (
      this.isApplicableAction('feePercentageSplits') &&
      this.splitsFormData.feePercentageSplits.length &&
      this.getSplitTotal('feePercentageSplits') !== 100
    ) {
      return false;
    }

    if (
      this.isApplicableAction('lines') &&
      this.lines.some((l) => !l.assetExtractor || !l.valueExtractor || !l.contactId || !l.categoryId)
    ) {
      return false;
    }

    return true;
  }

  private _getTransferRule(): TransferRule {
    const transferRule = {
      ...this.ruleFormData,
      walletId: this.conditionsFormData.walletIds, // Currently set to single select for backward compatibility
      coin: this.conditionsFormData.assets, // Currently set to text field for backward compatibility
      methodId: this.conditionsFormData.methodId,
      fromAddress: this.conditionsFormData.fromAddress,
      toAddress: this.conditionsFormData.toAddress,
      direction: this.conditionsFormData.direction ?? 'Empty',
      collapseValues: this.actionsFormData.collapsedLines,
      autoCategorizeFee: this.conditionsFormData.autoCategorizeFee,
      multiToken: this.conditionsFormData.multiToken,
      accountingConnectionId: this.actionsFormData.accountingConnectionId,
      includesCurrency: this.conditionsFormData.includesCurrency,
    };

    if (this.conditionsFormData.selectedMetadataField) {
      let metadataPair: MetadataPair[] = this.conditionsFormData.metadataInput?.split(',').map((value: string) => {
        return {
          key: this.conditionsFormData.selectedMetadataField,
          value: value?.trim() || undefined,
        };
      });

      // in case the field was left untouched, add only the key
      if (!metadataPair) metadataPair = [{ key: this.conditionsFormData.selectedMetadataField }];

      transferRule.metadataRule = {
        operator: MetadataOperator.OR,
        metadata: metadataPair,
      };
    }

    return transferRule;
  }

  private _getActionInput(): ActionInput {
    const type = this._getActionType();
    if (!type) {
      throw new Error('Invalid action type');
    }

    const splitMap = (split: PercentageSplitCategorizationInput): PercentageSplitCategorizationInput => {
      return {
        ...split,
        percentage: Number(split.percentage),
      };
    };

    return {
      type,
      categoryId: this.actionsFormData.categoryId,
      contactId: this.actionsFormData.contactId,
      feeContactId: this.actionsFormData.feeContactId,
      feeCategoryId: this.actionsFormData.feeCategoryId,
      deFiWalletId: this.actionsFormData.deFiWalletId,
      percentageSplits: this.splitsFormData.percentageSplits.map(splitMap),
      feePercentageSplits: this.splitsFormData.feePercentageSplits.map(splitMap),
      lines: this.lines,
      ignoreFailPricing: this.actionsFormData.ignoreFailPricing,
    };
  }

  private _getActionType(): ActionType | undefined {
    const actionType2 = this.actionsFormData.actionType;
    if (actionType2 === 'ignore') return ActionType.Ignore;

    const txnType = this.conditionsFormData.transactionType as RulesTxnType;
    if (actionType2 === 'categorize') {
      const txnToActionTypeMapping = {
        standard: ActionType.SimpleCategorization,
        trade: ActionType.TradeCategorization,
        internalTransfer: ActionType.InternalTransferCategorization,
        feeOnly: undefined, // TODO:
      };
      return txnToActionTypeMapping[txnType];
    }

    const advCatType = this.actionsFormData.advanceCategorize as AdvanceCategorizationType;
    if (advCatType && txnType === 'standard' && actionType2 === 'advancedCategorize') {
      const advCatToActionTypeMapping = {
        split: ActionType.SimpleSplitCategorization,
        defi: ActionType.DeFiCategorization,
        detailed: ActionType.DetailedCategorization,
      };
      return advCatToActionTypeMapping[advCatType];
    }

    return undefined;
  }

  private _resetData() {
    // TODO: there should be a more elegant solution to reset these data
    this.selectedTab = 'details';

    // set form data to default values
    this.ruleFormData = {
      id: null,
      disabled: false,
      name: null,
      priority: 1,
      description: null,
    };

    this.conditionsFormData = {
      walletIds: null,
      assets: null,
      multiToken: false,
      methodId: null,
      direction: null,
      afterDate: null,
      beforeDate: null,
      fromAddress: null,
      toAddress: null,
      subTxnFromAddress: null,
      subTxnToAddress: null,
      fromAssetQty: null,
      toAssetQty: null,
      transactionType: 'standard',
      autoCategorizeFee: false,
      includesCurrency: null,
      selectedMetadataField: null,
      metadataInput: null,
    };

    this.actionsFormData = {
      actionType: null,
      advanceCategorize: null,
      accountingConnectionId: null,
      collapseValues: false,
      ignoreFailPricing: false,
      categoryId: null,
      contactId: null,
      feeCategoryId: null,
      feeContactId: null,
      deFiWalletId: null,
    };

    this.splitsFormData = {
      percentageSplits: [],
      feePercentageSplits: [],
    };

    this.lines = [];
    this.collapsedLines = [];

    // reset states of expanded conditions and sections
    this.visibleConditions = [];
    this.conditions = { ...conditions };
    this.advancedConditions = { ...advancedConditions };
    this.expandedAdvancedConditions = false;
    this.$set(this, 'errorLine', null);
  }

  private _handleExpandOrCollapse(
    conditions: any,
    conditionKey: ConditionKey | AdvancedConditionKey,
    expanded: boolean
  ) {
    const updatedValue = { ...conditions[conditionKey], expanded: expanded };
    this.$set(conditions, conditionKey, updatedValue);

    if (!expanded) {
      if (conditions[conditionKey].formDataKeys) {
        for (const key of conditions[conditionKey].formDataKeys) {
          this.$set(this.conditionsFormData, key, null);
        }
      } else {
        this.$set(this.conditionsFormData, conditionKey, null);
      }
    }
  }

  private _populateForm() {
    if (!this.rule) return;

    if (this.rule.afterDateSEC) {
      this.$set(this.conditionsFormData, 'afterDate', this._secondsToISO(this.rule.afterDateSEC));
      this._handleExpandOrCollapse(this.conditions, 'date', true);
    }

    if (this.rule.beforeDateSEC) {
      this.$set(this.conditionsFormData, 'beforeDate', this._secondsToISO(this.rule.beforeDateSEC));
      this._handleExpandOrCollapse(this.conditions, 'date', true);
    }

    if (this.rule.beforeDateSEC || this.rule.afterDateSEC) this.visibleConditions.push('date');

    if (this.rule.metadataRule && this.rule.metadataRule.metadata?.length) {
      const metadataKey = this.rule.metadataRule.metadata[0].key;
      const metadataInput = this.rule.metadataRule.metadata.map((m) => m.value ?? '').join(',');

      if (!this.rule.metadataRule.metadata.every((m) => m.key === metadataKey)) {
        console.error('Metadata rule parsing is not reliable, unexpected metadata keys');
      }

      this.$set(this.conditionsFormData, 'selectedMetadataField', metadataKey);
      this.$set(this.conditionsFormData, 'metadataInput', metadataInput);
      this._handleExpandOrCollapse(this.conditions, 'metadataRule', true);
      this.visibleConditions.push('metadataRule');
    }

    for (const [key, value] of Object.entries(this.rule)) {
      if (isRuleKey(key)) this._populateRuleData(key, value);
      else if (isConditionKey(key)) this._populateCondition(key, value);
    }

    if (this.rule.includesCurrency) {
      this._populateAdvCondition('identifiers', 'includesCurrency', this.rule.includesCurrency);
    }

    this._populateBackwardCompatibleFields(this.rule);

    this.$set(this.actionsFormData, 'accountingConnectionId', this.rule.accountingConnectionId ?? null);
    this._populateActions(this.rule);
  }

  private _populateRuleData(key: RuleKey, value: unknown) {
    if (!value) return;
    this.$set(this.ruleFormData, key, value);
  }

  private _populateCondition(key: ConditionKey, value: unknown) {
    if (!value) return;
    this._handleExpandOrCollapse(this.conditions, key, true);
    this.$set(this.conditionsFormData, key, value);
    if (!this.conditions[key].alwaysVisible) this.visibleConditions.push(key);
  }

  private _populateAdvCondition(key: AdvancedConditionKey, dataKey: string, value: unknown) {
    this.$set(this, 'expandedAdvancedConditions', true);
    this._handleExpandOrCollapse(this.advancedConditions, key, true);
    this.$set(this.conditionsFormData, dataKey, value);
  }

  private _populateActions(rule: TransferRule) {
    let formUpdate: any = {};
    let txnType = 'standard';

    if (rule.action.type === 'Ignore') {
      formUpdate = {
        actionType: 'ignore',
      };
    } else if (rule.action.type === 'SimpleCategorization') {
      const action = rule.action as SimpleCategorizationAction;
      formUpdate = {
        actionType: 'categorize',
        categoryId: action.categoryId,
        contactId: action.contactId,
        feeCategoryId: action.feeCategoryId,
        feeContactId: action.feeContactId,
        ignoreFailPricing: action.ignoreFailPricing,
      };
    } else if (rule.action.type === 'InternalTransferCategorization') {
      const action = rule.action as InternalTransferCategorizationAction;
      txnType = 'internalTransfer';
      formUpdate = {
        actionType: 'categorize',
        feeContactId: action.internalFeeContactId,
        ignoreFailPricing: action.ignoreFailPricing,
      };
    } else if (rule.action.type === 'TradeCategorization') {
      const action = rule.action as TradeCategorizationAction;
      txnType = 'trade';
      formUpdate = {
        actionType: 'categorize',
        feeContactId: action.tradeFeeContactId,
        ignoreFailPricing: action.ignoreFailPricing,
      };
    } else if (rule.action.type === 'DeFiCategorization') {
      const action = rule.action as DeFiCategorizationAction;
      txnType = 'standard';
      formUpdate = {
        actionType: 'advancedCategorize',
        advanceCategorize: 'defi',
        feeContactId: action.deFiFeeContactId,
        deFiWalletId: action.deFiWalletId,
      };
    } else if (rule.action.type === 'SimpleSplitCategorization') {
      const action = rule.action as SimpleSplitCategorizationAction;
      txnType = 'standard';
      formUpdate = {
        actionType: 'advancedCategorize',
        advanceCategorize: 'split',
      };
      const splitsUpdate = {
        percentageSplits: action.splits,
        feePercentageSplits: action.feeSplits,
      };
      this.$set(this, 'splitsFormData', splitsUpdate);
    } else if (rule.action.type === 'DetailedCategorization') {
      const action = rule.action as DetailedCategorizationAction;
      txnType = 'standard';
      formUpdate = {
        actionType: 'advancedCategorize',
        advanceCategorize: 'detailed',
        ignoreFailPricing: action.ignoreFailPricing,
      };
      this.$set(this, 'lines', this._mapLines(action.lines));
    }

    if (rule.collapseValues) {
      formUpdate.collapseValues = rule.collapseValues;
    }

    this.$set(this.conditionsFormData, 'transactionType', txnType);
    this.$set(this, 'actionsFormData', { ...this.actionsFormData, ...formUpdate });
  }

  private _populateBackwardCompatibleFields(rule: TransferRule) {
    if (rule.walletId) {
      this.$set(this.conditionsFormData, 'walletIds', rule.walletId);
      this._handleExpandOrCollapse(this.conditions, 'walletIds', true);
      this.visibleConditions.push('walletIds');
    }

    if (rule.coin) {
      this.$set(this.conditionsFormData, 'assets', rule.coin);
      this._handleExpandOrCollapse(this.conditions, 'assets', true);
      this.visibleConditions.push('assets');
    }

    // REMARK: we cannot really support all comparisons in a 2 range fields
    // this is a naive approach to at least support rules created from V2 UI
    if (rule.valueRules) {
      for (const vRule of rule.valueRules) {
        if (vRule.comparison === Comparison.Gte) {
          this._populateAdvCondition('assetQty', 'fromAssetQty', vRule.value);
        } else if (vRule.comparison === Comparison.Lte) {
          this._populateAdvCondition('assetQty', 'toAssetQty', vRule.value);
        }
      }
    }
  }

  private _mapLines(lines?: DetailedCategorizationActionLine[]) {
    return lines
      ? lines.map((line) => {
          const { __typename, lineQualifierExtractor, metadataIds, ...otherAttrs } =
            line as DetailedCategorizationActionLine;
          const metadataCount = this.metadata.length;
          const mappedMetadataIds = new Array(metadataCount).fill(null);
          if (metadataIds) {
            // iterate through metadataIds passed in by the line
            metadataIds.forEach((id) => {
              // iterate throught the metadata count which is the length of this.metadatas
              let j = -1;
              for (let i = 0; i < metadataCount; i++) {
                const subMetadata = this.filteredMetadata[i];
                if (subMetadata) {
                  const findMetadata = subMetadata.metadata.find((m) => m.id === id);
                  if (findMetadata) {
                    j = i;
                    break;
                  }
                }
              }
              if (j >= 0) {
                mappedMetadataIds[j] = id;
              }
            });
          }
          return {
            ...otherAttrs,
            lineQualifierExtractor: lineQualifierExtractor ? (lineQualifierExtractor as string) : null,
            metadataIds: mappedMetadataIds,
          };
        })
      : [];
  }

  private _secondsToISO(seconds: number) {
    return DateTime.fromSeconds(seconds, {
      zone: this.$store.state.currentOrg.timezone,
    }).toISODate();
  }

  private _assetQtyToComparison() {
    const comparisons: RuleComparisonInput[] = [];
    if (this.conditionsFormData.fromAssetQty) {
      comparisons.push({
        comparison: Comparison.Gte,
        value: this.conditionsFormData.fromAssetQty,
      });
    }

    if (this.conditionsFormData.toAssetQty) {
      comparisons.push({
        comparison: Comparison.Lte,
        value: this.conditionsFormData.toAssetQty,
      });
    }

    return comparisons;
  }

  private _showSnackbar(action: string, message: string) {
    this.$store.commit(MUT_SNACKBAR, {
      color: action,
      message,
    });

    if (action !== 'success') this.$set(this, 'errorLine', message);
  }
}
