























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CreateRuleModal extends Vue {
  @Prop()
  public expanded?: boolean;

  @Prop()
  public readonly label?: string;

  @Prop()
  public alwaysVisible?: boolean;

  @Prop()
  public collapsable?: boolean;

  @Prop()
  public required?: boolean;
}
