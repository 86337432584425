
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

type ButtonToggleValue = string | number | Array<string | number>;

@Component
export default class UiButtonToggle extends Vue {
  @Prop()
  public value?: ButtonToggleValue;

  @Prop({ required: true })
  public values!: { value: string; id: number | string; disabled?: boolean }[];

  @Prop()
  public multiple?: boolean;

  @Prop()
  public required?: boolean;

  @Prop({ default: false })
  public vertical?: boolean;

  public selected: Array<string | number> = [];

  mounted() {
    this.checkSelected();
  }

  public onSelect(id: string | number) {
    if (this.selected.includes(id) && (!this.required || (this.required && this.selected.length > 1))) {
      this.selected = this.selected.filter((v) => v !== id);
    } else {
      if (this.multiple) this.selected.push(id);
      else this.selected = [id];
    }
    this.$emit('change', this.multiple ? this.selected : this.selected[0] ?? null);
  }

  private checkSelected() {
    this.selected = !this.value ? [] : this.value instanceof Array ? this.value : [this.value];
  }

  @Watch('value')
  onValueChange() {
    this.checkSelected();
  }
}
