import { ActionType, AssetExtractor, Coins, Comparison, TransactionDirection, ValueExtractor } from '@/api-svc-types';

type ConstantLookup<T, K> = {
  id: T;
  value: K;
  disabled?: boolean;
};

export const coins: Coins[] = [Coins.Eth, Coins.Btc];

export const categorization: ConstantLookup<ActionType, string>[] = [
  { id: ActionType.Ignore, value: 'Ignore' },
  { id: ActionType.SimpleCategorization, value: 'Simple Categorization' },
  { id: ActionType.DetailedCategorization, value: 'Detailed Categorization' },
  { id: ActionType.TradeCategorization, value: 'Trade Categorization' },
  { id: ActionType.InternalTransferCategorization, value: 'Internal Transfer Categorization' },
  { id: ActionType.SimpleSplitCategorization, value: 'Split Categorization' },
  { id: ActionType.DeFiCategorization, value: 'DeFi Categorization' },
];

export const directions: ConstantLookup<TransactionDirection, string>[] = [
  { id: TransactionDirection.Inbound, value: 'Inbound' },
  { id: TransactionDirection.Outbound, value: 'Outbound' },
  { id: TransactionDirection.All, value: 'All' },
  { id: TransactionDirection.Empty, value: 'Empty' },
];

export const priorities: ConstantLookup<number, number>[] = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 },
  { id: 6, value: 6 },
  { id: 7, value: 7 },
  { id: 8, value: 8 },
  { id: 9, value: 9 },
  { id: 10, value: 10 },
];

export const comparisonsTypes: ConstantLookup<Comparison, string>[] = [
  { id: Comparison.Eq, value: 'Equal' },
  { id: Comparison.Lt, value: 'Less than' },
  { id: Comparison.Lte, value: 'Less than or equal to' },
  { id: Comparison.Gt, value: 'Greater than' },
  { id: Comparison.Gte, value: 'Greater than or equal to' },
];

export const valueExtractorTypes: ConstantLookup<ValueExtractor, string>[] = [
  { id: ValueExtractor.Royalty, value: 'Royalty' },
  { id: ValueExtractor.Commission, value: 'Commission' },
  { id: ValueExtractor.Amount, value: 'Amount' },
  { id: ValueExtractor.Fee, value: 'Fee' },
  { id: ValueExtractor.Revenue, value: 'Revenue' },
  { id: ValueExtractor.LrcFee, value: 'LRC Marketplace Partner Fee' },
  { id: ValueExtractor.TotalFee, value: 'LRC Marketplace Total Trade Fee' },
];

export const assetExtractorTypes: ConstantLookup<AssetExtractor, string>[] = [
  { id: AssetExtractor.Eth, value: 'ETH' },
  { id: AssetExtractor.Token, value: 'TOKEN' },
  { id: AssetExtractor.Coin, value: 'COIN' },
  { id: AssetExtractor.Fiat, value: 'FIAT' },
];

export type RulesTxnType = 'standard' | 'trade' | 'internalTransfer' | 'feeOnly';
export const txnCategories: ConstantLookup<RulesTxnType, string>[] = [
  { value: 'Standard', id: 'standard' },
  { value: 'Trade', id: 'trade' },
  { value: 'Internal Transfers', id: 'internalTransfer' },
  { value: 'Fee Only', id: 'feeOnly', disabled: true },
];

export type AdvanceCategorizationType = 'split' | 'defi' | 'detailed';
export const advanceCategorization: ConstantLookup<AdvanceCategorizationType, string>[] = [
  { value: 'Split Categorize', id: 'split' },
  { value: 'Defi Categorize', id: 'defi' },
  { value: 'Detailed Categorize', id: 'detailed' },
];

export type ActionType2 = 'categorize' | 'advancedCategorize' | 'ignore';
export const actionTypes: ConstantLookup<ActionType2, string>[] = [
  { value: 'Categorize', id: 'categorize' },
  { value: 'Advanced Categorize', id: 'advancedCategorize' },
  { value: 'Ignore', id: 'ignore' },
];
